import { jsx as _jsx } from "react/jsx-runtime";
import { Tag as ChTag, TagCloseButton as ChTagCloseButton, TagLabel as ChTagLabel, } from '@chakra-ui/tag';
export const Tag = (props) => {
    return _jsx(ChTag, { ...props });
};
export const TagCloseButton = (props) => {
    return _jsx(ChTagCloseButton, { ...props });
};
export const TagLabel = (props) => {
    return _jsx(ChTagLabel, { ...props });
};
