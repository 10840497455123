import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { getUser, updateUser } from '@playful/api';
import { fromPromise } from '@playful/utils';
import useSWR from 'swr';
export function useUser(_ref) {
  var id = _ref.id,
    fallbackData = _ref.fallbackData;
  var _useSWR = useSWR(id ? "users/".concat(id) : null, function () {
      return id ? getUser(id) : undefined;
    }, {
      fallbackData: fallbackData
    }),
    data = _useSWR.data,
    error = _useSWR.error,
    mutate = _useSWR.mutate;
  var updateUserMutation = function updateUserMutation(updatedUser) {
    return mutate(function () {
      return updateUser(id, updatedUser);
    }, {
      optimisticData: _objectSpread(_objectSpread({}, data), updatedUser),
      populateCache: true,
      revalidate: false,
      rollbackOnError: true
    });
  };
  return {
    user: data,
    updateUser: function updateUser(updatedUser) {
      return fromPromise(updateUserMutation(updatedUser));
    },
    error: error
  };
}